import {Save,Obtener,Editar} from '@/Generico/Conexion';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import {mainAxios} from '@/plugins/axios';
class TipoMadera
{
    tipoMaderaId?:number = 0;
    descripcion?:string ="";
    origenId?:number =0;
    origenName:string ="";
    descuento?:string="";
    vendorSap?:string ="";
    factorHuecoSuelto?:number=0;
    factorHuecoBulto?:number =0;
    descuentoValor?:number=0;
    estado?:boolean = false;

    constructor(_tipoMaderaId?:number,_origenId?:number,_descripcion?:string,_descuento?:string,_vendorSap?:string,_factorHuecoSuelto?:number,_factorHuecoBulto?:number,_descuentoValor?:number)
    {
            this.tipoMaderaId = _tipoMaderaId;
            this.descripcion = _descripcion;
            this.origenId = _origenId;
            this.descuento = _descuento;  
            this.vendorSap = _vendorSap;
            this.factorHuecoSuelto = _factorHuecoSuelto;
            this.factorHuecoBulto  =_factorHuecoBulto;
            this.descuentoValor = _descuentoValor;
    }
}

async function  GuardartipoMadera(tipoMadera:TipoMadera):Promise<ResponseGenerico>
{
    return await Save<TipoMadera>("tipoMadera/Guardar",tipoMadera,mainAxios);
} 

async function  EditartipoMadera(tipoMadera:TipoMadera):Promise<ResponseGenerico>
{
    return await Editar<TipoMadera>("tipoMadera/Actualizar",tipoMadera,mainAxios);
} 

async function  EditarEstadotipoMadera(tipoMadera:TipoMadera):Promise<ResponseGenerico>
{
    return await Editar<TipoMadera>("tipoMadera/CambiarEstado",tipoMadera,mainAxios);
} 

async function ObtenertipoMadera()
{
    return await Obtener<TipoMadera>("tipoMadera/Obtener",mainAxios);
}


async function ObtenerSelectTipoMadera()
{
    return await Obtener<TipoMadera>("tipoMadera/ObtenerSelect",mainAxios);
}

export 
{
    TipoMadera,
    GuardartipoMadera,
    EditartipoMadera,
    EditarEstadotipoMadera,
    ObtenertipoMadera,
    ObtenerSelectTipoMadera
}